const readme = `
                                        WELCOME TO 
                                        
▓█████▄  ▒█████   ███▄    █  ██ ▄█▀      █     █░ ▒█████   ██▀███   ██▓    ▓█████▄ 
▒██▀ ██▌▒██▒  ██▒ ██ ▀█   █  ██▄█▒      ▓█░ █ ░█░▒██▒  ██▒▓██ ▒ ██▒▓██▒    ▒██▀ ██▌
░██   █▌▒██░  ██▒▓██  ▀█ ██▒▓███▄░      ▒█░ █ ░█ ▒██░  ██▒▓██ ░▄█ ▒▒██░    ░██   █▌
░▓█▄   ▌▒██   ██░▓██▒  ▐▌██▒▓██ █▄      ░█░ █ ░█ ▒██   ██░▒██▀▀█▄  ▒██░    ░▓█▄   ▌
░▒████▓ ░ ████▓▒░▒██░   ▓██░▒██▒ █▄ ██▓ ░░██▒██▓ ░ ████▓▒░░██▓ ▒██▒░██████▒░▒████▓ 
 ▒▒▓  ▒ ░ ▒░▒░▒░ ░ ▒░   ▒ ▒ ▒ ▒▒ ▓▒ ▒▓▒ ░ ▓░▒ ▒  ░ ▒░▒░▒░ ░ ▒▓ ░▒▓░░ ▒░▓  ░ ▒▒▓  ▒ 
 ░ ▒  ▒   ░ ▒ ▒░ ░ ░░   ░ ▒░░ ░▒ ▒░ ░▒    ▒ ░ ░    ░ ▒ ▒░   ░▒ ░ ▒░░ ░ ▒  ░ ░ ▒  ▒ 
 ░ ░  ░ ░ ░ ░ ▒     ░   ░ ░ ░ ░░ ░  ░     ░   ░  ░ ░ ░ ▒    ░░   ░   ░ ░    ░ ░  ░ 
   ░        ░ ░           ░ ░  ░     ░      ░        ░ ░     ░         ░  ░   ░    
 ░                                   ░                                      ░      


                                    CHECK OUT THE BANDCAMP


                              CHECK THE CALENDAR FOR UPCOMING GIGS


                                            P.L.U.R                                          
 
                                    ..:::------------:::..                                  
                              .:------------------------------:.                            
                          .:--------------------------------------:.                        
                      .:-----------------------=----------------------:.                    
                   .:------------------------=++=------------------------:.                 
                 .--------------------------=+***=--------------------------.               
               .---------------------------=+*****=---------------------------:             
             .----------------------------=********+----------------------------:           
           .------------------++=--------+**********+-----------------------------.         
          :-------------------=+*+=-----+************+=----------------------------:        
         -------------------=++***+=--=+**************+=-----------------------------       
        -----------------=++*++==++=-=+****************+=-===++++++++-----------------.     
       ----------------=+**+=-------=+****************++=+***********=-----------------.    
      ----------------=+*+=--------=+*************++==----=********+=-------------------.   
     ---------------=+**+----------=************+==------=+******+=----------------------   
    :--------------=+*+=------------=+********+=--------=+****++=-------------------------  
   .---------------+*+=--------------=+*******=--------=+*++==----------------------------. 
   ---------------+*+=----------------=+******+=-----=====--------------------------------- 
   --------------=**=-------------------+********++**+-------------------------------------.
  :--------------+*+---------------------=**********=--------------------------------------:
  :--------------+*+----------------------=+******+=----------------------------------------
  --------------=**=-----------------------=+****+=-------------------=========-------------
  --------------=**=-----====================+**+===================++********+-------------
  --------------=**=---=+************************************++=-----=+*******=-------------
  :-------------=**+--=+**********************==**********++=---------=+****+=--------------
  :--------------+*+-=+*********************+=--=+******++=-------------+++=---------------:
   --------------=**+**********************+=----=+****+=------------===+=-----------------.
   ---------------+==+********************+=------=+***+=--------==++*****+---------------- 
   .--------------+=-=+******************+----------+****+++++++***********+--------------. 
    :-----------=+*+--=+****************=------------=**********************+=------------  
     ----------=+***+=-=+*************+=--------------=+*********************+=----------   
      --------=+*****+=-=+***********+=----------------=+*********************+=--------.   
       ------=+++++++++===+++++++++++=------------------=+++++++++++++++++++++++=------.    
        ----------------=+**+=--------------------------------------------------------.     
         -----------------=+**+==-----------------------==---------------------------       
          :-----------------=++**+==--------------------+*+=-----------------------:        
           .-------------------=++***++===-----------===+**+----------------------.         
             .--------------------===+++***+++++++++***+++**=-------------------:           
               .------------------------=====++++++====--=++------------------:             
                 .----------------------------------------------------------.               
                   .:----------------------------------------------------:.                 
                      .:----------------------------------------------:.                    
                          .:--------------------------------------:.                        
                              .:------------------------------:.                            


`;

export default readme;
