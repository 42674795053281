import * as icons from "../icons";
import readme from "./textFiles/readme";

const desktopData = [
  {
    title: "My Computer",
    icon: icons.computer32,
    component: "ExplorerWindow",
    data: {
      content: [
        {
          title: "(C:)",
          icon: "cd32",
          failState: {
            message:
              "This is a React App, there is no CD drive, your laptop probably doesn't have one either",
            loadTime: 4000,
          },
        },
        {
          title: "(D:)",
          icon: "hdd32",
          failState: {
            message: "This is a React App, there is no hard drive",
            loadTime: 1000,
          },
        },
        {
          title: "3 1/2 Floppy (A:)",
          icon: "floppy32",
          failState: {
            message:
              "Did everyone else's computer take ages to load the 'no floppy disc inserted' message or was that just mine?",
            loadTime: 8000,
          },
        },
      ],
    },
  },
  {
    title: "READ ME",
    icon: icons.notepadFile32,
    component: "Notepad",
    data: {
      content: readme,
      readOnly: true,
      width: "680",
      height: "700",
    },
  },
  {
    title: "Calendar",
    icon: icons.calendar,
    component: "IframeWindow",
    data: {
      src: "https://calendar.google.com/calendar/embed?src=6bo6hfdn7bfgqrsm7n5agmtcbk%40group.calendar.google.com&ctz=Australia%2FSydney",
      maximizeOnOpen: false,
    },
    multiInstance: true,
  },
  {
    title: "Winamp",
    icon: icons.winamp,
    component: "IframeWindow",
    data: {
      src: "/programs/winamp/",
      maximizeOnOpen: false,
      width: "280",
      height: "400",
    },
    multiInstance: false,
  },

  {
    title: "Bandcamp",
    icon: icons.bandcamp,
    component: "IframeWindow",
    data: { src: "https://donkworld.bandcamp.com/", maximizeOnOpen: true },
    multiInstance: true,
  },
  {
    title: "Pipes",
    icon: icons.pipes,
    component: "IframeWindow",
    data: {
      src: "/programs/pipes/",
      maximizeOnOpen: true,
    },
    multiInstance: false,
  },
  {
    title: "3D FlowerBox",
    icon: icons.pipes,
    component: "IframeWindow",
    data: {
      src: "/programs/3D-FlowerBox/",
      maximizeOnOpen: true,
    },
    multiInstance: false,
  },
  {
    title: "Archive",
    icon: icons.olddonk,
    component: "IframeWindow",
    data: { src: "/programs/archive/", maximizeOnOpen: true },
    multiInstance: true,
  },
];

export default desktopData;
