import React, { Component } from "react";
import { WindowProgram } from "packard-belle";
import buildMenu from "../../helpers/menuBuilder";
import "./_styles.scss";
import Window from "../tools/Window";
import PureIframe from "./Iframe";

class IFrame extends Component {
  state = {
    displayAlert: this.props.data.displayAlert || true
  };

  confirm = () => this.setState({ displayAlert: false });

  render() {
    const { props } = this;
    return (
      <Window
        {...props}
        className={"IframeWindow"}
        initialHeight={props.data.height || 380}
        initialWidth={props.data.width || 440}
        minWidth={props.data.width}
        minHeight={props.data.height}
        menuOptions={props.data.useMenu && buildMenu(props)}
        Component={WindowProgram}
        resizable={!(props.data.width || props.data.height)}
        maximizeOnOpen={props.data.maximizeOnOpen || false}
        hideOnDrag={true}
      >
        <div style={props.data && props.data.style}>
          <PureIframe src={props.data.src} title={props.title} />
        </div>
      </Window>
    );
  }
}

export default IFrame;
